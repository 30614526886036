<template>
  <section
    class="
      side-nav
      bg-legend-black
      dark:bg-dark-mode-secondary
      h-screen
      xl:sticky
      lg:sticky
      md:absolute
      absolute
      top-0
      flex-shrink-0
      z-40
    "
  >
    <div>
      <div class="mt-9 mb-12 w-full relative">
        <img
          v-if="sideNavCollapsed"
          src="@/assets/images/legend-pay-logo-mini.svg"
          alt="back to home page"
          class="mx-auto cursor-pointer"
          @click="$router.push('/')"
        />
        <img
          v-else
          src="@/assets/images/legend-pay-logo-light.svg"
          alt="back to home page"
          class="mx-auto cursor-pointer"
          @click="$router.push('/')"
        />
        <div
          class="
            hamburger-icon
            w-9
            h-9
            bg-primary-color
            rounded-full
            flex
            items-center
            justify-center
            absolute
            top-8
            -right-5
            border-2 border-white
            cursor-pointer
            z-30
          "
          @click="showMenu"
        >
          <img
            v-if="!sideNavCollapsed"
            src="@/assets/images/close-icon.svg"
            alt="collapse navigation bar"
          />
          <img
            v-else
            src="@/assets/images/hamburger-logo-white.svg"
            alt="open navigation bar"
          />
        </div>
      </div>
      <div class="text-white text-sm">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Dashboard',
          }"
        >
          <img
            src="@/assets/images/dashboard-icon-light.svg"
            alt="go to dashboard"
          />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Dashboard</span
          >
        </router-link>
        <router-link
          :to="{ name: 'Products' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction':
              $route.name === 'Products' || $route.name === 'AddProduct',
          }"
        >
          <img src="@/assets/images/products.svg" alt="go to products" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Products</span
          >
        </router-link>
        <router-link
          :to="{ name: 'Payments' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Payments',
          }"
        >
          <img src="@/assets/images/wallet.svg" alt="go to payments" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Payment</span
          >
        </router-link>
        <router-link
          :to="{ name: 'Staff' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Staff',
          }"
        >
          <img src="@/assets/images/staff.svg" alt="go to Staff" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden">Staff</span>
        </router-link>
        <router-link
          :to="{ name: 'Orders' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Orders',
          }"
        >
          <img
            src="@/assets/images/shopping-bag-white.svg"
            alt="go to orders"
          />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden">Orders</span>
        </router-link>
        <router-link
          :to="{ name: 'Refunds' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Refunds',
          }"
        >
          <img src="@/assets/images/refund.svg" alt="go to orders" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Refunds</span
          >
        </router-link>
        <router-link
          v-if="user && user.role.toLowerCase() === 'admin'"
          :to="{ name: 'Settlements' }"
          class="flex px-12 py-4"
          :class="{
            'bg-legend-black-interaction': $route.name === 'Settlements',
          }"
        >
          <img src="@/assets/images/money-stack.svg" alt="go to Settlements" />
          <span class="nav-item animate ml-5 flex-shrink-0 hidden"
            >Settlements</span
          >
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidenav',
  mounted() {
    this.onViewportResize()
    window.addEventListener('resize', () => {
      this.onViewportResize()
    })
    this.sideNavCollapsed = true
  },
  watch: {
    $route() {
      if (window.innerWidth < 1024) {
        this.collapsNavBarMobile()
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      sideNavCollapsed: true,
    }
  },
  methods: {
    
    onViewportResize() {
      // const nodes = document.querySelectorAll('.nav-item')
      // const sideNav = document.querySelector('.side-nav')
      // const hamburger = document.querySelector('.hamburger-icon')

      // if (window.innerWidth < 1024) {
      //   sideNav.classList.add('-ml-64')
      //   hamburger.classList.add('-right-24')
      //   hamburger.classList.add('-top-2')
      //   nodes.forEach((node) => {
      //     node.classList.remove('hidden')
      //   }) 
      // } else {
      //   sideNav.classList.remove('-ml-64')
      //   hamburger.classList.remove('-right-24')
      //   hamburger.classList.add('-right-5')
      //   hamburger.classList.remove('-top-2')
      //   hamburger.classList.add('top-8')
      //   nodes.forEach((node) => {
      //     node.classList.add('hidden')
      //   })
      // }
    },
    collapsNavBar() {
      const nodes = document.querySelectorAll('.nav-item')
      nodes.forEach((node) => {
        if ([...node.classList].includes('hidden')) {
          node.classList.remove('hidden')
          this.sideNavCollapsed = false
        } else {
          node.classList.add('hidden')
          this.sideNavCollapsed = true
        }
      })
    },
    showMenu() {
      if (window.innerWidth < 1024) {
        this.collapsNavBarMobile()
      } else {
        this.collapsNavBar()
      }
    },
    collapsNavBarMobile() {
      const sideNav = document.querySelector('.side-nav')
      const hamburger = document.querySelector('.hamburger-icon')

      if ([...sideNav.classList].includes('-ml-64')) {
        this.sideNavCollapsed = false
        sideNav.classList.remove('-ml-64')
        sideNav.classList.add('ml-0')
        hamburger.classList.remove('-right-24')
        hamburger.classList.add('-right-5')
        hamburger.classList.remove('-top-2')
        hamburger.classList.add('top-8')
      } else {
        sideNav.classList.remove('ml-0')
        sideNav.classList.add('-ml-64')
        hamburger.classList.remove('-right-5')
        hamburger.classList.add('-right-24')
        hamburger.classList.remove('top-8')
        hamburger.classList.add('-top-2')
        this.sideNavCollapsed = true
      }
    },
    sideNavEvent(event) {
      const sideNav = document.querySelector('.side-nav')
      const hamburger = document.querySelector('.hamburger-icon')

      const tagName = event.target.tagName.toLowerCase()
      if (tagName.toLowerCase() === 'span' || tagName === 'a') {
        sideNav.classList.remove('ml-0')
        sideNav.classList.add('-ml-64')
        hamburger.classList.remove('-right-5')
        hamburger.classList.add('-right-24')
        hamburger.classList.remove('top-8')
        hamburger.classList.add('-top-2')
        this.sideNavCollapsed = true
      }
    },
  },
}
</script>
