<template>
  <main
    class="
      flex
      bg-primary-background
      dark:bg-dark-mode-main dark:text-border-color
      relative
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <SideNav v-if="!isExempted" />
    <section class="w-full xl:mx-10 lg:mx-0 md:mx-0 mx-0">
      <AppBar
        v-if="!isExempted"
        @open-notification="openNotification = true"
        @open-orders="openOrders = true"
      />
      <router-view />
    </section>
    <custom-modal v-if="openOrders">
      <OrdersUnprocessed
        slot="modal"
        @close-cart="openOrders = false"
        @order-update="updateOrder"
        @error-message="displayError"
      />
    </custom-modal>
    <custom-modal v-if="openNotification">
      <NotificationSystem
        :notifications="notificationItems"
        slot="modal"
        @close-cart="openNotification = false"
      />
    </custom-modal>
  </main>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import SideNav from '@/components/SideNav.vue'
import { mapGetters } from 'vuex'
import NotificationSystem from '@/components/Notifications.vue'
import OrdersUnprocessed from '@/components/OrdersUnprocessed.vue'
import errorModule from '@/util/error.handle'

export default {
  name: 'RootComponent',
  components: { AppBar, SideNav, NotificationSystem, OrdersUnprocessed },
  mounted() {
    if (this.token) {
      this.getProfile()
      this.getWalletBalance()
    }
  },
  watch: {
    token() {
      if (!this.token) {
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 500)
      }
    },
  },
  computed: {
    ...mapGetters(['token', 'walletBalance', 'user']),
    isExempted() {
      return this.exemptedRoutes.includes(this.$route.name)
    },
  },
  data() {
    return {
      notification: null,
      openOrders: false,
      openNotification: false,
      notificationItems: [
        {
          message: 'notification message',
          created_at: 'date created',
        },
      ],
      exemptedRoutes: [
        'Register',
        'Login',
        'Verify',
        'Forgot',
        'CreatePassword',
        'NewPassword',
      ],
    }
  },
  methods: {
    updateOrder(message) {
      this.openOrders = false
      this.notification = {
        type: 'success',
        message,
      }
    },
    async getProfile() {
      try {
        const { status, data } = await this.axios.get('/account')
        if (status === 200) {
          this.$store.commit('SET_USER', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    displayError(error) {
      this.notification = errorModule(
        error,
        this.notification,
        this.$router,
        this.$store
      )
    },
    async getWalletBalance() {
      try {
        if (this.token) {
          const { status, data } = await this.axios.get('/account/wallet')

          if (status === 200) {
            this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262324;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
