var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"side-nav bg-legend-black dark:bg-dark-mode-secondary h-screen xl:sticky lg:sticky md:absolute absolute top-0 flex-shrink-0 z-40"},[_c('div',[_c('div',{staticClass:"mt-9 mb-12 w-full relative"},[(_vm.sideNavCollapsed)?_c('img',{staticClass:"mx-auto cursor-pointer",attrs:{"src":require("@/assets/images/legend-pay-logo-mini.svg"),"alt":"back to home page"},on:{"click":function($event){return _vm.$router.push('/')}}}):_c('img',{staticClass:"mx-auto cursor-pointer",attrs:{"src":require("@/assets/images/legend-pay-logo-light.svg"),"alt":"back to home page"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('div',{staticClass:"hamburger-icon w-9 h-9 bg-primary-color rounded-full flex items-center justify-center absolute top-8 -right-5 border-2 border-white cursor-pointer z-30",on:{"click":_vm.showMenu}},[(!_vm.sideNavCollapsed)?_c('img',{attrs:{"src":require("@/assets/images/close-icon.svg"),"alt":"collapse navigation bar"}}):_c('img',{attrs:{"src":require("@/assets/images/hamburger-logo-white.svg"),"alt":"open navigation bar"}})])]),_c('div',{staticClass:"text-white text-sm"},[_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Dashboard',
        },attrs:{"to":{ name: 'Dashboard' }}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard-icon-light.svg"),"alt":"go to dashboard"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Dashboard")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction':
            _vm.$route.name === 'Products' || _vm.$route.name === 'AddProduct',
        },attrs:{"to":{ name: 'Products' }}},[_c('img',{attrs:{"src":require("@/assets/images/products.svg"),"alt":"go to products"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Products")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Payments',
        },attrs:{"to":{ name: 'Payments' }}},[_c('img',{attrs:{"src":require("@/assets/images/wallet.svg"),"alt":"go to payments"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Payment")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Staff',
        },attrs:{"to":{ name: 'Staff' }}},[_c('img',{attrs:{"src":require("@/assets/images/staff.svg"),"alt":"go to Staff"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Staff")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Orders',
        },attrs:{"to":{ name: 'Orders' }}},[_c('img',{attrs:{"src":require("@/assets/images/shopping-bag-white.svg"),"alt":"go to orders"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Orders")])]),_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Refunds',
        },attrs:{"to":{ name: 'Refunds' }}},[_c('img',{attrs:{"src":require("@/assets/images/refund.svg"),"alt":"go to orders"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Refunds")])]),(_vm.user && _vm.user.role.toLowerCase() === 'admin')?_c('router-link',{staticClass:"flex px-12 py-4",class:{
          'bg-legend-black-interaction': _vm.$route.name === 'Settlements',
        },attrs:{"to":{ name: 'Settlements' }}},[_c('img',{attrs:{"src":require("@/assets/images/money-stack.svg"),"alt":"go to Settlements"}}),_c('span',{staticClass:"nav-item animate ml-5 flex-shrink-0 hidden"},[_vm._v("Settlements")])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }