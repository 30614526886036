<template>
  <nav class="w-full flex items-center flex-row-reverse py-8">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <div
      v-if="user"
      class="flex items-center relative"
      v-click-outside="hideOptions"
    >
      <div
        class="w-8 h-8 rounded-full bg-gray-100 border-2 border-primary-color"
      ></div>
      <div
        class="ml-3 xl:block lg:block md:hidden hidden cursor-pointer"
        @click="showOption = !showOption"
      >
        <h2 class="text-sm font-semibold leading-3 capitalize">
          {{ user.first_name }} {{ user.last_name }}
        </h2>
        <div class="text-xs text-legend-gray dark:text-border-color mt-1">
          @{{ user.username }}
        </div>
      </div>
      <img
        src="@/assets/images/chevron-down.svg"
        alt="user profile"
        class="ml-3 p-2 cursor-pointer"
        @click="showOption = !showOption"
      />
      <ul
        v-if="showOption"
        class="
          absolute
          top-full
          right-0
          mt-3
          text-sm
          capitalize
          bg-white
          dark:bg-dark-mode-primary
          border border-main-border
          rounded-md
          w-32
          font-semibold
          shadow
          z-50
        "
      >
        <li
          v-if="$route.name !== 'Profile'"
          class="hover:bg-gray-100 cursor-pointer"
        >
          <router-link
            :to="{
              name: 'Profile',
              params: { username: this.user.username },
            }"
            class="block p-3"
            >profile</router-link
          >
        </li>
        <li class="p-3 hover:bg-gray-100 cursor-pointer" @click="logout">
          logout
        </li>
      </ul>
    </div>
    <div class="mx-7" @click="$emit('open-notification')">
      <img src="@/assets/images/notification-icon.svg" alt="notifications" />
    </div>
    <div class="flex items-center cursor-pointer" @click="$emit('open-orders')">
      <img src="@/assets/images/shopping-bag.svg" alt="orders" />
      <span
        class="
          ml-2
          capitalize
          font-semibold
          text-sm
          xl:inline-flex
          lg:inline-flex
          md:inline-flex
          hidden
        "
        >my orders</span
      >
      <span
        v-if="unprocessedOrders"
        style="min-width: 18px; height: 18px"
        class="
          bg-primary-red
          text-white text-xs
          flex
          items-center
          justify-center
          rounded-full
          ml-1
          p-1
        "
        >{{ unprocessedOrders.total }}</span
      >
    </div>
    <SwithTheme @toggle-mode="changeMode" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import SwithTheme from '@/components/UI/Switch.vue'
import errorModule from '@/util/error.handle'

export default {
  name: 'AppBar',
  components: { SwithTheme },
  mounted() {
    if (this.token) {
      this.fetchOrders()
    }
  },
  watch: {
    $route: {
      handler() {
        this.showOption = false
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['user', 'unprocessedOrders', 'token']),
  },
  data() {
    return {
      showOption: false,
      notification: null,
    }
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideOptions() {
      this.showOption = false
    },
    changeMode() {
      this.$store.commit('CHANGE_MODE')
    },
    async logout() {
      try {
        const { status } = await this.axios.get('/account/logout')

        if (status === 200) {
          this.$store.commit('RESET_TOKEN')
          this.$store.commit('RESET_USERNAME')
          localStorage.removeItem('LP-merchant')
          localStorage.clear()
          window.location.href = '/login'
        }else{
          this.loading = false
          this.submitting = false
          this.notification = {
            type: 'error',
            message: "login failed try again!",
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchOrders() {
      try {
        const { status, data } = await this.axios.get(
          '/account/orders?filter=unprocessed'
        )

        if (status === 200) {
          this.$store.commit('SET_ORDERS', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
