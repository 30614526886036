import axios from 'axios'
import store from '@/store/index'

const temp = process.env.NODE_ENV
let base_url

if (temp === 'development') {
  base_url = process.env.VUE_APP_API_BASE_URL
} else if (temp === 'production') {
  base_url = process.env.VUE_APP_API_BASE_URL_PRODUCTION
}

const instance = axios.create({
  baseURL: base_url,
})

if (store.getters.token) {
  const { access_token, authorization } = store.getters.token.data
  instance.defaults.headers.common[
    'Authorization'
  ] = `${authorization} ${access_token}`
}


export default instance
