<template>
  <main
    class="
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      pb-20
      px-8
      text-legend-gray
      dark:text-border-color
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <div
      v-if="unprocessedOrders && !unprocessedOrders.data.length"
      class="font-semibold text-center text-lg py-12"
    >
      No pending orders
    </div>
    <div
      v-else
      class="
        sticky
        top-0
        pb-4
        z-10
        bg-primary-background
        dark:bg-dark-mode-main
      "
    >
      <x-icon
        size="1.3x"
        class="custom-class block mb-6 -ml-4 text-primary-color cursor-pointer"
        @click="$emit('close-cart')"
      ></x-icon>
      <h1
        class="
          font-bold
          text-xl
          capitalize
          text-legend-black
          dark:text-border-color
        "
      >
        New Orders
      </h1>
      <p class="my-4 text-legend-gray dark:text-border-color">
        This Includes Orders that are New and Processing
      </p>
    </div>
    <div
      v-for="(order, index) in unprocessedOrders.data"
      :key="index"
      class="
        w-full
        mt-6
        bg-white
        dark:bg-dark-mode-primary
        p-3
        rounded-lg
        flex
        items-start
      "
    >
      <div class="w-1/3 flex-shrink-0 h-20 bg-gray-200 rounded-md">
        <img
          v-if="order.product_image.length"
          :src="`${url}/${order.product_image[0].path}/${order.product_image[0].name}.${order.product_image[0].mimetype}`"
          :alt="order.name"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="w-full ml-4">
        <h1 class="text-lg capitalize">{{ order.product_name }}</h1>
        <div class="w-full flex items-center justify-between my-2">
          <span
            class="
              bg-green-200
              text-secondary-green text-sm
              rounded-full
              py-1
              px-3
            "
            >{{ order.quantity }}
            {{ order.quantity > 1 ? 'pieces' : 'piece' }}</span
          >
          <span class="font-bold text-xl"
            >₦{{ order.price | moneyFormat }}</span
          >
        </div>
        <div class="my-2 capitalize">
          <span class="font-bold mr-2">Buyer:</span>
          <span>{{ order.buyer }}</span>
        </div>
        <div class="flex items-center capitalize">
          <span class="font-bold mr-2">status:</span>
          <div class="w-full relative">
            <div
              class="
                w-full
                h-8
                border border-border-color
                bg-transparent
                rounded-lg
                px-2
                capitalize
                focus:outline-none
                flex
                items-center
                text-sm
              "
              @click="showOrderOptions = !showOrderOptions"
            >
              {{ order.order_status }}
            </div>
            <ul
              v-if="showOrderOptions"
              class="
                text-sm
                absolute
                left-0
                top-full
                bg-white
                border
                shadow-lg
                dark:bg-border-color dark:text-dark-mode-primary
                rounded-md
                w-full
              "
            >
              <li
                v-for="(status, index) in statuses"
                :key="index"
                class="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                @click.stop="selectStatus({ status, id: order.id })"
              >
                {{ status }}
              </li>
            </ul>
            <chevron-down-icon
              size="1x"
              class="custom-class absolute right-4 top-2"
            ></chevron-down-icon>
          </div>
        </div>
        <button
          class="text-white bg-primary-color py-1 mt-4 rounded-lg block w-full"
          :class="{ 'inactive-state': !selectedOrder || loading }"
          @click="updateOrderStatus"
        >
          Save
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'OrdersUnprocessed',
  components: {
    XIcon,
    ChevronDownIcon,
  },
  data() {
    return {
      notification: null,
      selectedOrder: null,
      showOrderOptions: false,
      loading: false,
      statuses: ['Pending', 'Processing', 'Completed', 'Cancelled'],
    }
  },
  computed: {
    ...mapGetters(['unprocessedOrders']),
  },
  methods: {
    selectStatus(data) {
      this.selectedOrder = data
      this.showOrderOptions = false
      const order = this.unprocessedOrders.data.find(
        (tick) => tick.id === data.id
      )
      order.order_status = data.status
    },
    async updateOrderStatus() {
      try {
        if (!this.selectedOrder) {
          this.notification = {
            type: 'error',
            message: `
            <h1 class="font-semibold">Nothing to update!</h1>
            <p>Select a status to update</p>
            `,
          }
          return
        }

        this.loading = true

        const { status, data } = await this.axios.put(
          `/account/orders/${this.selectedOrder.id}`,
          {
            status: this.selectedOrder.status,
          }
        )

        if (status === 200) {
          this.loading = false
          this.$emit('order-update', data.message)
        }
      } catch (error) {
        this.loading = false
        this.$emit('error-message', error)
      }
    },
  },
}
</script>
