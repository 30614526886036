const state = {
    unprocessedOrders: null,
  }
  
  const getters = {
    unprocessedOrders: (state) => state.unprocessedOrders,
  }
  
  const actions = {}
  
  const mutations = {
    SET_ORDERS: (state, orders) => state.unprocessedOrders = orders,
  }

  export default {
    state,
    getters,
    actions,
    mutations,
  }