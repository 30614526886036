import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import theme from './modules/theme'
import orders from './modules/orders'
import user from './modules/user'

//Load Vuex
Vue.use(Vuex)

//Create store
export default new Vuex.Store({
  modules: {
    theme,
    user,orders
  },
  plugins: [createPersistedState({
    key: 'LP-merchant',
  })],
})
