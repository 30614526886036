export default (error, notification, router, store) => {
    if(error.response.status === 400 || error.response.status === 422){
        notification = {
            type: 'error',
            message: error.response.data.message,
        }

        return notification
    }else if(error.response.status === 401 || error.response.status === 403){
        store.commit('RESET_TOKEN')
          store.commit('RESET_USERNAME')
          localStorage.removeItem('LP-merchant')
        // return router.push({name: 'Login'})
    }
}