import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)
const token = store.getters.token

console.log(token)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: 'login' */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: 'login' */ '../views/Register.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () =>
      import(/* webpackChunkName: 'forgot' */ '@/views/Forgot.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/password',
    name: 'Password',
    component: () =>
      import(/* webpackChunkName: 'password' */ '@/views/SetNewPassword.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/account/secure',
    name: 'NewPassword',
    component: () =>
      import(
        /* webpackChunkName: 'newpassword' */ '@/views/SetNewPassword.vue'
      ),
      meta: { requiresGuest: true },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () =>
      import(
          /* webpackChunkName: 'dashboard' */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/orders',
    name: 'Orders',
    component: () =>
      import(/* webpackChunkName: 'orders' */ '../views/Orders.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/settlements',
    name: 'Settlements',
    component: () =>
      import(/* webpackChunkName: 'settlements' */ '../views/Settlements.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/payments',
    name: 'Payments',
    component: () =>
      import(/* webpackChunkName: 'payments' */ '../views/Payment.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/payments/refunds',
    name: 'Refunds',
    component: () =>
      import(/* webpackChunkName: 'refunds' */ '../views/Refunds.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/products',
    name: 'Products',
    component: () =>
      import(/* webpackChunkName: 'products' */ '../views/Products.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/produts/new',
    name: 'AddProduct',
    component: () =>
      import(/* webpackChunkName: 'addproduct' */ '../views/AddProduct.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/produts/edit',
    name: 'EditProduct',
    component: () =>
      import(/* webpackChunkName: 'editproduct' */ '../views/EditProduct.vue'),
    meta: { requiresAuth: true },

  },
  {
    path: '/produts/:id',
    name: 'ViewProduct',
    component: () =>
      import(/* webpackChunkName: 'viewproduct' */ '../views/ViewProduct.vue'),
    meta: { requiresAuth: true },
    props: true

  },
  {
    path: '/staff',
    name: 'Staff',
    component: () =>
      import(/* webpackChunkName: 'staff' */ '../views/Staff.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile/:username',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: 'profile' */ '@/views/Profile.vue'),
      meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  //Check for requiresAuth Guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //Check login status
    if (!token) {
      //Take back to login if user isn't authenticated
      next({ path: '/login', query: { redirect: to.fullPath }, })
    } else {
      next()
    }
  }  else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (token) {
      return router.go(-1)
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
