const state = {
  token: null,
  username: null,
  user: null,
  walletBalance: null,
}

const getters = {
  token: (state) => state.token,
  username: (state) => state.username,
  walletBalance: (state) => state.walletBalance,
  user: (state) => state.user,
}

const actions = {}

const mutations = {
  SET_TOKEN: (state, token) => (state.token = token),
  SET_USERNAME: (state, username) => (state.username = username),
  SET_WALLET_BALANCE: (state, walletBalance) =>
    (state.walletBalance = walletBalance),
  RESET_TOKEN: (state) => (state.token = null),
  RESET_USERNAME: (state) => {
    state.username = null
    state.user = null
    state.walletBalance = null
  },
  SET_USER: (state, user) => (state.user = user),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
